import React, { useEffect, useState } from "react";
import Card from "../Card";

import backgroundImage from "./../../assets/img/reformHomepageHeroStatic.png";
import reformHeroVideo from "./../../assets/video/reformHeroVideo.mp4";
import { Button } from "./../../shadcn-ui/ui/button";
import Loader from "../Loader";
import { formatNumber, Request } from "../../library/functions";
import { GET_TRADINGSTATUS_URL, GET_TVL_URL } from "../../library/constants";

export default function HomepageHero() {
  return (
    <Card className="h-full w-full relative overflow-hidden z-10">
      <div className="z-10 xl:py-[80px] lg:py-[50px] pt-[110px] pb-[80px] lg:px-[70px] xl:px-[110px] px-8 h-full h-md:py-[35px] h-md:px-[50px]">
        <HomepageHeroContent />
      </div>

      <video
        src={reformHeroVideo}
        poster={backgroundImage}
        autoPlay
        muted
        loop
        className="absolute top-0 left-0 object-cover w-full h-full -z-10 opacity-40"
      />
    </Card>
  );
}

function HomepageHeroContent() {
  return (
    <div className="flex flex-col lg:flex-row items-end h-full w-full justify-between gap-12">
      <div className="h-full w-full flex flex-col justify-between gap-8">
        <h1 className="text-4xl max-w-[400px]">
          <span className="text-primary">REFORM</span> THE MARKET MAKING{" "}
          <span className="text-primary">INDUSTRY</span>
        </h1>
        <div className="w-full">
          <Stats />
        </div>
      </div>
      <div className="w-full lg:max-w-[300px] xl:max-w-[300px] 3xl:max-w-[380px] flex flex-col gap-6">
        <p className="text-[#D9D9D9] text-sm leading-6">
          Reform is the first market making and liquidity providing DAO
          leveraging AI whilst aligning incentives across all stakeholders
          within the market making process.
        </p>
        <a href="https://docs.reformdao.com/" target="_blank" rel="noreferrer">
          <Button variant={"outline"} size={"lg"} className="w-fit">
            EXPLORE MORE
          </Button>
        </a>
      </div>
    </div>
  );
}
function Stats() {
  const [totalValueLockedStat, setTotalValueLockedStat] = useState<
    number | null
  >(null);
  const [totalTradesStat, setTotalTradesStat] = useState<number | null>(null);
  const [totalTradingVolumeStat, setTotalTradingVolumeStat] = useState<
    number | null
  >(null);

  useEffect(() => {
    getTotalValueLockedStat(setTotalValueLockedStat);
    getTradingStats(setTotalTradesStat, setTotalTradingVolumeStat);
  }, []);

  return (
    <div className="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 w-full gap-4">
      {totalValueLockedStat ? (
        <Stat
          title={"Total value locked"}
          value={formatNumber(totalValueLockedStat)}
          percentage={60}
          prefix="$"
        />
      ) : (
        <Loader />
      )}
      {totalTradesStat ? (
        <Stat
          title={"Total\ntrades"}
          value={formatNumber(totalTradesStat)}
          percentage={75}
        />
      ) : (
        <Loader />
      )}
      {totalTradingVolumeStat ? (
        <Stat
          title={"Total Trading volume"}
          value={formatNumber(totalTradingVolumeStat)}
          percentage={45}
          prefix="$"
        />
      ) : (
        <Loader />
      )}
      <Stat
        title={"Revenue Generated"}
        value={"152.9K"}
        percentage={60}
        prefix="$"
      />
    </div>
  );
}

function Stat({
  title,
  value,
  percentage,
  prefix,
}: {
  title: string;
  value: string;
  percentage: number;
  prefix?: string;
}) {
  return (
    <div className="flex items-end gap-6 relative">
      <div className="min-w-[2px] bg-[#2D2D2D] h-[93%] flex flex-col justify-end">
        <div
          className={"w-full bg-primary"}
          style={{ height: percentage + "%" }}
        ></div>
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-[#B2B1AD] max-w-[130px] text-sm whitespace-pre-line">
          {title.toUpperCase()}
        </p>
        <p className="text-xl">
          {prefix}
          {value}
        </p>
      </div>
    </div>
  );
}

async function getTotalValueLockedStat(setTotalValueLockedStat: Function) {
  const config = {
    url: GET_TVL_URL,
    method: "get",
  };

  Request(config)
    .then((response: any) => {
      if (!response || !response.locked) return;

      let totalValueLockedStat = response.locked / 10 ** 18;
      setTotalValueLockedStat(totalValueLockedStat);
      return;
    })
    .catch((err) => {
      console.log(err);
    });
}
async function getTradingStats(
  setTotalTradesStat: Function,
  setTotalTradingVolumeStat: Function
) {
  const config = {
    url: GET_TRADINGSTATUS_URL,
    method: "get",
  };

  Request(config)
    .then((response: any) => {
      console.log(response);
      if (!response) return;

      let totalTradesStat = response?.totalTradesAmount;
      let totalTradingVolumeStat = response?.totalVolumeInUsdt;

      if (!totalTradesStat) return;
      setTotalTradesStat(totalTradesStat);

      if (!totalTradingVolumeStat) return;
      setTotalTradingVolumeStat(totalTradingVolumeStat);
      return;
    })
    .catch((err) => {
      console.log(err);
    });
}
