import React from "react";
import Card from "../Card";
import { mainPartners, ReformPartner, sidePartners } from "./../../library/partners";
import { Button } from "./../../shadcn-ui/ui/button";

import hackenLogo from "./../../assets/svg/partnerLogos/hackenLogo.svg";
import certikLogo from "./../../assets/svg/partnerLogos/certikLogo.svg";

import discordLogo from "./../../assets/svg/discordLogo.svg";
import mediumLogo from "./../../assets/svg/mediumLogo.svg";
import twitterLogo from "./../../assets/svg/twitterLogo.svg";
import telegramLogo from "./../../assets/svg/telegramLogo.svg";

type Props = {};

export default function HomePageSideBar({}: Props) {
  return (
    <div className="flex flex-col justify-between p-4 lg:p-6 gap-12 h-full overflow-y-scroll">
      <div className="xl:pt-4">
        <h1 className="text-3xl mb-6">REFORM ECOSYSTEM</h1>
        <PartnerGrid>
          {mainPartners.map((mainPartner) => {
            return <DisplayPartner partner={mainPartner} key={mainPartner.link} />;
          })}
        </PartnerGrid>
        <div className="mt-12"></div>
        <PartnerGrid>
          {sidePartners.map((sidePartner) => {
            return <DisplayPartner partner={sidePartner} key={sidePartner.link} />;
          })}
        </PartnerGrid>
      </div>
      <div className="flex flex-col gap-12 lg:gap-18 h-sm:pb-[50px]">
        <SmartContracts />
        <Community />
      </div>
    </div>
  );
}

function DisplayPartner({ partner }: { partner: ReformPartner }) {
  return (
    <a href={partner.link} target="_blank" rel="noreferrer">
      <div className="flex items-center justify-center h-[80px] h-sm:h-[60px] bg-[#303030] xl:p-3 lg:p-2 p-1">
        <Button
          variant="ghost"
          size="iconFree"
          className="h-[70px] w-full grid grid-rows-[30px_1fr] gap-2 items-center justify-center"
        >
          <div className="flex justify-center items-center h-full w-full">
            <img src={partner.logo} alt={partner.display} className="max-h-[30px] mt-1" />
          </div>
          <p className="text-[10px] text-white/50 word-break text-wrap leading-[14px] mt-0.5">{partner.display}</p>
        </Button>
      </div>
    </a>
  );
}

function PartnerGrid({ children }: { children: React.ReactNode }) {
  if (!children) return <></>;

  const baseRowCount = 4;
  let itemCount = React.Children.count(children);
  let lastRowItemCount = itemCount % baseRowCount;

  // Convert children to an array (if it is not already an array)
  const childrenArray = React.Children.toArray(children);

  // Append a new <div> to the children array
  if (lastRowItemCount > 0) {
    for (let i = 0; i < baseRowCount - lastRowItemCount; i++) {
      childrenArray.push(<div className="bg-[#303030]" key={"empty" + i}></div>);
    }
  }

  return <div className="grid grid-cols-4 bg-neutral-600 gap-[1px] -mx-2">{childrenArray}</div>;
}

function SmartContracts() {
  return (
    <div className="">
      <p>SMART CONTRACTS AUDITED BY</p>
      <div className="flex items-center gap-10 mt-10 mx-2">
        <img src={hackenLogo} alt={"Hacken logo"} className="h-[45px]" />
        <img src={certikLogo} alt={"Certik logo"} className="h-[35px]" />
      </div>
    </div>
  );
}
function Community() {
  return (
    <div className="">
      <p>COMMUNITY</p>
      <div className="mt-4 flex items-center gap-4">
        <a href={"https://discord.gg/reformdao"} target="_blank" rel="noreferrer">
          <Button variant="ghost" className="h-10 2xl:px-6 px-4">
            <img src={discordLogo} alt={"discord"} />
          </Button>
        </a>{" "}
        <a href={"https://blog.reformdao.com/"} target="_blank" rel="noreferrer">
          <Button variant="ghost" className="h-10 2xl:px-6 px-4">
            <img src={mediumLogo} alt={"medium"} />
          </Button>
        </a>{" "}
        <a href={"https://x.com/reformdao"} target="_blank" rel="noreferrer">
          <Button variant="ghost" className="h-10 2xl:px-6 px-4">
            <img src={twitterLogo} alt={"twitter"} />
          </Button>
        </a>{" "}
        <a href={"https://t.me/Reformportal"} target="_blank" rel="noreferrer">
          <Button variant="ghost" className="h-10 2xl:px-6 px-4">
            <img src={telegramLogo} alt={"telegram"} />
          </Button>
        </a>
      </div>
    </div>
  );
}
